export default () => {
  const authStore = useAuthStore();

  function getFeature(featureName) {
    return authStore.company?.features?.find((feature) => feature.name === featureName);
  }

  function has(featureName) {
    
    const feature = getFeature(featureName);
    
    return feature?.active || false;
  }

  function hasQuantity(featureName, quantity) {
    
    const feature = getFeature(featureName);
    
    return feature?.active && feature.quantity > quantity || false;
  }

  function quantity(featureName) {
    const feature = getFeature(featureName);
    return feature?.quantity || 0;
  }

  return { has, hasQuantity, quantity };
}
